<template>
    <section>
      <Toast />
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Historial Sincronización Entregas Masivas</p>
          <Button @click="$router.go(-1)" class="rounded-md ml-auto" size="small" label="Regresar" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <div class="w-full">
            <label class="text-xs text-gray-600" for="numero_orden">Número de orden</label>
            <InputText class="w-full" id="numero_orden" @keyup.enter="agregarOv" v-model="ordenSeleccionada" @keydown="validacionOrdenes($event)" />
            <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-scroll">
              <div v-for="(orden, i) in ordenesFiltradas" :key="i" class="">
                <div class="flex p-1 bg-blue-200 text-blue-600 rounded-md">
                  <p class="text-xs">{{ orden }}</p>
                  <button @click="eliminarOrden(orden)"><i class="pi pi-times text-xs"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="status">Estado</label>
            <Dropdown
              panelClass="text-xs"
              class="w-full rounded-md border-gray-300"
              v-model="filtros.StatusSync"
              :options="tipoStatus"
              optionLabel="label"
              optionValue="value"
              id="status"
            >
            </Dropdown>
          </div>
          <div class="flex items-end gap-2 col-span-3 mb-2">
            <Button
              @click="listarHistorial"
              size="small"
              class="rounded-md"
              label="Buscar"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
            <Button
              @click="limpiarFiltros"
              size="small"
              class="rounded-md"
              label="Limpiar"
              severity="warning"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
            <Button
              @click="entregaMasiva"
              size="small"
              class="rounded-md"
              severity="success"
              label="Sincronizar SAP"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
          </div>
        </div>
        <div class="w-full my-6">
          <DataTable class="p-datatable-sm text-xs" v-model:selection="selectedOv" :value="ordenes.rows" responsiveLayout="scroll">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column
              header="No. Orden"
              :pt="{
                headerContent: { style: { justifyContent: 'center' } }
              }"
            >
              <template #body="{data}">
                <div class="text-center pt-3">
                  <p>{{ data.Id }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button @click="$router.push({ name: 'pharmasan.ventas.ov.entrega' })" class=" underline italic">Ver</button>
                    <!-- <button class="underline italic">Editar</button> -->
                  </div>
                </div>
              </template>
            </Column>
            <Column
              header="No. Entrega SAP"
              :pt="{
                headerContent: { style: { justifyContent: 'center' } }
              }"
            >
              <template #body="{data}">
                <div class="text-center">
                  {{ data.NumEntrega ?? '---'}}
                </div>
              </template>
            </Column>
            <Column field="createdAt" header="Fecha">
              <template #body="{data}">
                  {{ dayjs(data.createdAt).format('YYYY-MM-DD HH:mm') }}
              </template>
            </Column>
            <Column field="CardName" header="Cliente"></Column>
            <Column field="Paciente" header="Paciente">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.U_ACS_TpoIdentf + ' - ' + data.U_PHR_NumDcto }}</span>
                  <span>{{ data.U_ACS_NmbPct }}</span>
                </div>
              </template>
            </Column>
            <Column field="U_PHR_ModContratoDesc" header="Modalidad"></Column>
            <Column field="U_PHR_MotAutorizaDesc" header="Motivo"></Column>
            <Column field="U_PHR_BodegaNombre" header="Bodega"></Column>
            <Column field="CreadoPor" header="Creado por"></Column>
            <Column header="Estado">
              <template #body="{data}">
                <div v-tooltip="data.ServicelayerResponse ? data.ServicelayerResponse : 'Sin procesar'" class="p-2 rounded-md text-center" :class="data.StatusSync === 2 ? 'bg-green-300' : ((data.StatusSync === 1 || data.StatusSync === 0) ? 'bg-blue-300' : 'bg-red-400')">
                  <p class="font-bold">
                    {{ data.StatusSync === 0 ? 'Enviado' : (data.StatusSync === 1 ? 'Procesando' : (data.StatusSync === 2 ? 'Completado' : (data.StatusSync === 3 ? 'Error' : 'Sin Enviar'))) }}
                  </p>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(ordenes.count)"
            :rowsPerPageOptions="[5,10,20,30,100]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
          />
        </div>
      </div>
    </section>
</template>
<script setup>
  import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  import { useToast } from 'primevue/usetoast'
  import EntregaService from '../../../services/sync-entrega.service'
  import { onMounted, ref, computed } from 'vue'
    // name: 'ListadoOrdenesVenta',
      // Servicios
      const toast = useToast()
      const _EntregaService = ref(new EntregaService())
      // Referencias
      const ordenes = ref([])
      const ordenesFiltradas = ref([])
      const ordenSeleccionada = ref()
      const selectedOv = ref([])
      const tipoStatus = ref([])
      const limit = ref(10)
      const offset = ref(0)
      const filtros = ref({
        StatusSync: true
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          ...filtros.value
        }
      })
      // Metodos
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        listarHistorial(params.value)
      }
      const listarHistorial = () => {
        if (ordenesFiltradas.value.length) {
          filtros.value.Id = JSON.stringify(ordenesFiltradas.value)
        }
        const object = {
          ...params.value
        }
        // object.StatusSync = 1
        _EntregaService.value.listarHistorial(object).then(({ data }) => {
          ordenes.value = data
        })
      }
      const listarTipoStatus = () => {
        _EntregaService.value.listarTipoStatus().then(({ data }) => {
          tipoStatus.value = data
        })
      }
      const agregarOv = () => {
        for (const i of ordenSeleccionada.value.split(' ')) {
          if (ordenesFiltradas.value && ordenesFiltradas.value.length) {
            if (!ordenesFiltradas.value.some(a => a === i)) {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          } else {
            if (i !== '') {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          }
        }
        ordenSeleccionada.value = ''
      }
      const validacionOrdenes = (e) => {
        if (/[^0-9\s]/.test(e.key)) {
          if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
            if (e.key === 'v' && !e.ctrlKey) {
              e.preventDefault()
            }
          } else {
            e.preventDefault()
          }
        }
      }
      const eliminarOrden = (numeroOv) => {
        const index = ordenesFiltradas.value.findIndex(a => a === numeroOv)
        ordenesFiltradas.value.splice(index, 1)
      }
      const limpiarFiltros = () => {
        filtros.value = {
          StatusSync: true
        }
        ordenesFiltradas.value = []
        ordenSeleccionada.value = ''
        listarHistorial()
      }
      const entregaMasiva = () => {
        if (selectedOv.value.length) {
          var valid = 0
          selectedOv.value.map(o => {
            if (o.StatusSync !== 3) {
              valid += 1
            }
          })
          if (valid === 0) {
            Swal.fire({
              title: '¿Está seguro?',
              text: 'Esta acción no se puede deshacer',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, estoy seguro'
            }).then(async (result) => {
              if (result.isConfirmed) {
                var ordenesSend = selectedOv.value.map(m => m.Id)
                _EntregaService.value.editEntrega(ordenesSend).then((res) => {
                  toast.add({ severity: 'success', summary: 'Exito', detail: 'Se reenvió la entrega correctamente', life: 3000 })
                  listarHistorial()
                })
              }
            })
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Enviar Entrega',
              text: 'Solo puede enviar entregas con errores'
            })
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Enviar Entrega',
            text: 'Debe seleccionar al menos una Entrega'
          })
        }
      }
      onMounted(() => {
        listarHistorial()
        listarTipoStatus()
      })

</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
