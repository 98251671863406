import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_ORDENES_VENTA

export default class EntregaService {
  listarOrdenes (params) {
    return http.get(`${baseUrl}/sync/ov`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarHistorial (params) {
    return http.get(`${baseUrl}/sync/ov`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearEntrega (data) {
    return http.post(`${baseUrl}/sync/ov`, data, {
      headers: {
        loading: true
      }
    })
  }

  editEntrega (data) {
    return http.put(`${baseUrl}/sync/ov`, data, {
      headers: {
        loading: true
      }
    })
  }

  listarTipoStatus (params) {
    return Promise.resolve({ data: [{ value: true, label: 'Todos' }, { value: 0, label: 'Pendiente' }, { value: 1, label: 'Procesando' }, { value: 2, label: 'Completado' }, { value: 3, label: 'Error' }] })
    // return http.get(`${baseUrl}/sync/ov`, {
    //   params,
    //   headers: {
    //     loading: true
    //   }
    // })
  }
}
